// Theme color
$borderColor:#a6fed9;

/* #Navigation
================================================== */

.start-header {
    padding-top: 5px;
	opacity: 1;
	transform: translateY(0);
    z-index: 2;
    .container{
        @include setMinRwd{
            max-width: 1760px;
            margin-right: 1em;
        }
        img{
            margin: 0;
        }
    }

    @include setMaxRwd{
        z-index: 9999;
    }
}

// 參考範例 : https://codepen.io/typo3-freelancer/pen/poEvyGj

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

// Assigning the above animation to all dropdown menus
.dropdown-menu{
    border: $borderColor 1px solid;
    z-index: 3;
    &.show{
         animation: fadeIn 0.3s alternate;
    }
}

.nav-link{
    font-family: $fontNotosanc-tc;
    @include fontSize(22px);
    padding: 25px 20px !important;
    
    &,
    &:hover,
    &.active{
        color: #fff !important;
    }

    @include setWidthRwd(1020px,1100px){
        @include fontSize(18px);
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    @include setMaxRwd{
        padding-top: 10px !important;
        padding-bottom: 5px !important;
    }

    @include setWidthRwd(1020px,1100px){
        padding-top: 5px !important;
    }
}

// Mega Menu adjustments
.nav-item{
    margin-left: 5px;

    img{
        margin: 0;
    }

    // 主連結
    &-link{
        &,
        .nav-link{
            position: relative;
        }

        .nav-link{
            & ~ .dropdown-menu{
                @include radius(0);
            }

            @include setStyleMinRwd(1020px){
                &::after {
                    content: "";
                    display: none;
                    vertical-align: 0.255em;
                    border-top: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
    
                    position: absolute;
                    top: 80%;
                    left: 50%;
                    margin-left: -0.15em;
                }
                
                &:hover,
                &.active{
                    &::after{
                        display: inline-block;
                    }
                }

                & ~ .dropdown-menu{
                    max-width: 160px;
                    left: 50%;
                    margin-left: -80px;
                    top: 100%;
                }
            }

            @include setWidthRwd(1020px,1100px){
                &::after {
                    top: 90%;
                }
            }

            @include pad{
                & ~ .dropdown-menu{
                    border: none;
                    margin-right: 18px;
                    margin-left: 20px;
                }
            }
        }

        @include setStyleMinRwd(1020px){
            &:hover{
                .nav-link{
                    &::after{
                        display: inline-block;
                    }
                }

                & > .dropdown-menu{
                    display: block;
                }
            }
        }
    }

    // 語言
    &-lang{
        display: flex;
        align-items: center;
        margin-left: 1.25em;
        .nav-link{
            @include fontSize(16px);
            color: $borderColor !important;
            line-height: 1.75;
            border: $borderColor 1px solid;
            padding: 5px 22px 7px !important;
        }

        .dropdown{
            &-menu {
                min-width: 100%;
                border-radius: 0;
                padding: 0;
                top: 85%;
                li{
                    &:not(:last-child){
                        margin-bottom: 1px;
                    }
                }
            }

            &-item{
                // color: #205e43;
                // background: #a6fed9;
                padding-top: 0.5em;
                padding-bottom: 0.5em;

                &:hover,
                &:focus{
                    background-color: $borderColor;
                    color: $colorItem;
                }
            }
        }

        @include pad{
            margin-top: 1.25em;
            .nav-link {
                display: none;
            }

            .dropdown{
                &-menu {
                    min-width: auto;
                    width: calc(100% - 1.25em);
                    border-radius: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-around;
                    li{
                        flex: 1;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.navbar{
    &-brand{
        @include setMinRwd{
            position: absolute;
            top: 1.75em;
        }

        @include setMaxRwd{
            width: 10vw;
        }

        @include pad{
            width: 15vw;
        }

        @include mobile{
            width: 20vw;
        }
    }

    // Complete styles for main navbar toggler and hamburger menu
    &-toggler{
        border: none;
        padding: 0;
        outline: none;
        &:focus{
            box-shadow: none;
        }
        
        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: #fff;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0px;
                        top: auto;
                        bottom: 10%;
                        transform-origin: 50% 50%;
                    }
                }

                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        // 
        &.fixed{
            display: none;
            position: fixed;
            left: 60%;
            top: 2%;
            z-index: 1001;
            .hamburger-toggle{
                .hamburger{
                    span {
                        &:first-child{
                            transform: rotate(40deg);
                        }
                        &:last-child{
                            transform: translateY(-16px) rotate(-40deg);
                        }
                    }
                }
            }
            
            &.active{
                display: block;
            }
            
        }
    }

    &-collapse{
        @include pad{
            position: fixed;
            @include setSize(100%,100vh);
            top: 0;
            left: -100%;
            background: #8c9f97;
            transition: 0.15s;
            overflow-y: auto;
            max-width: 60vw;
            &,
            &>ul{
                z-index: 100;
            }

            &>ul{
                position: relative;
                justify-content: flex-start !important;
                min-height: 100%;
            }

            &.show{
                left: 0;
            }
        }
    }

    &-overlay{
        display: block;
        @include setSize(100%,100vh);
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }

        @include setMinRwd{
            display: block;
        }
    }

    // 下拉
    &-mobile-icon{
        @include setSize(80px,40px);
        color: #fff !important;
        text-align: right;
        padding-right: 18px;
        position: absolute;
        right: 0;
        top: 15px;
        &::after {
            display: none;
        }
        &.show{
            .fa{
                transform: rotate(180deg);
            }
        }
    }
}

.dropdown-item{
    white-space: normal;
}

.icons{
  display: inline-flex;
  margin-left: auto;
  a{
    transition: all 0.2s ease-in-out;
    padding: 0.2rem 0.4rem;
    color: #ccc !important;
    text-decoration: none;
    &:hover{
      color: white;
      text-shadow: 0 0 30px white;
    }
  }
}