.index{

    // 內框
    &-content{
        .list{
            height: 100%;
        }

        .title{
            font-weight: 600;
        }

        .txt{
            text-align: center;
            margin-top: 1em;
            margin-bottom: 1em;
            font-size: get-vw-mobile(23px);
            @include setMinRwd{
                @include fontSize(20px);
            }
        }

        .btn{
            padding: 0.7em 1.95em;
        }
        
        @include setMaxRwd{
            padding-left: 1rem;
            padding-right: 1rem;
            
            .btn{
                line-height: 1.25;
                padding: 0.5em 1em; 
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    // 頁面使用
    &-page{

        &About{
            @include setBackSet(center,100%);
            background-image: url('../images/index-about-bg.png');
            padding-top: 195px;
            margin-top: -100px;
            z-index: 1;

            .title-mainHeadingPage{
                p{
                    color: #fff !important;
                }

                .title{
                    &-ch{
                        &::after {
                            background-color: #fff;
                        }
                    }
    
                    &-en{
                        opacity: 0.25;
                        margin-top: 10px;
                    }
                }
            }

            @include setMinRwd{
                margin-top: -120px;
                margin-bottom: -100px;
                &-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 6em;
                }
    
                &-item{
                    flex: none;
                    width: 560px;
                }
    
                &-text{
                    flex: 1;
                    padding-left: 12%;
                }
            }

            @include setWidthRwd(1150px,1500px){
                background-size: cover;
                padding-left: 3em;
                padding-right: 3em;
    
                &-text{
                    padding-left: 5%;
                }
            }

            @include setWidthRwd(1381px,1500px){
                margin-top: -242px;
                margin-bottom: -50px;
                padding-top: 265px;
            }

            @include setWidthRwd(1290px,1380px){
                margin-top: -215px;
            }

            @include setWidthRwd(1150px,1289px){
                margin-top: -240px;
                margin-bottom: 0;
                padding-top: 241px;
                padding-bottom: 241px;
            }

            @include setWidthRwd(1020px,1100px){
                background-position: top;
                background-size: cover;
                margin-top: -200px;
            }

            @include setMaxRwd{
                background-size: cover;
                padding-top: 140px;
                padding-bottom: 120px;
            }
        }

        &Application{
            z-index: 0;
            &::before{
                content: '';
                display: block;
                @include setSize(120%,128%);
                background-color: #eeeeee;
                position: absolute;
                top: -26%;
                left: -10%;
                transform: rotate(3deg);
                z-index: -1;

                @include setMaxRwd{
                    width: 100%;
                    height: 121%;
                    left: 0;
                    transform: rotate(0deg);
                }
            }

            @include setMinRwd{
                margin-bottom: 4em;
                padding-bottom: 70px;
            }
        }
    }
}

// 共用Banner形象圖
.page{
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(1050px,1100px){
            margin-top: 11%;
        }

        .bannerSwiper{
            @include setMinRwd{
                padding-bottom: 3em;
                &::before{
                    @include setBackSet(left,contain);
                }
            }
        }

        .swiper{
            &-pagination{
                &-bullets {
                    bottom: 0 !important;
                    @include setMinRwd{
                        width: auto !important;
                        left: auto !important;
                        bottom: auto !important;
                        right: 1.5em;
                        top: 50%;
                        transform: translate3d(0px, -50%, 0);
                        border: #fff 1px solid;
                        padding: 0.5em;
                        border-radius: 1em;
                    }

                    @include setMaxRwd{
                        bottom:-1.5em !important
                    }
                }
                &-bullet {
                    width: 12px !important;
                    height: 12px !important;
                    background-color: #000;
                    &-active{
                        background-color: $colorMain !important;
                        border-radius: 0.75em !important;
                    }
                    
                    @include setMinRwd{
                        display: block;
                        margin: 0.3em 0 !important;
                        transition: height 200ms ease-in;
                        background-color: #fff;
                        opacity: 1;
                        &-active{
                            height: 1.75em !important;
                        }
                    }

                    @include setMaxRwd{
                        &-active{
                            width: 1.75em !important;
                            margin: 0 0.3em !important;
                        }
                    }
                }
            }

            // 內容
            &-slideInfo{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-left: auto;

                // 圖片
                .imgInfo{
                    text-align: right;
                    flex: 1;
                    overflow: hidden;

                    @include setMinRwd{
                        padding-top: 1em;
                        padding-right: 1.5em;
                    }

                    @include setWidthRwd(1410px,1500px) {
                        padding-top: 6em;
                    }

                    @include setWidthRwd(1101px,1400px){
                        flex: none;
                        max-width: 60em;
                    }

                    @include setWidthRwd(1300px,1400px){
                        max-width: 68em;
                        padding-top: 3em;
                    }
                }

                // 文字
                .txtInfo{
                    text-align: right;
                    position: absolute;
                    top: 50%;
                    right: 200px;
                    padding-right: 2%;
                    z-index: 1;

                    &::after{
                        content: '';
                        display: block;
                        @include setSize(50%,2px);
                        background-color: #fff;
                        position: absolute;
                        right: -50%;
                        top: 50%;
                    }

                    p{
                        margin-bottom: 0;
                        color: #fff
                    }
 
                    .title{
                        @include fontSize(50px);
                        line-height: 1;
                        font-family: $fontBs;
                        font-weight: 900;
                    }

                    .txt{
                        @include fontSize(70px);
                        line-height: 1.5;
                        font-family: $Alfa;
                        p{   
                            opacity: 0.27;
                        }
                    }

                    @include setWidthRwd(1101px,1400px){
                        .title{
                            @include fontSize(36px);
                        }
                    }
                }

                @include pad{
                    flex-direction: column;
                    .imgInfo{
                        order:-1;
                    }
                    .txtInfo{
                        width: 100%;
                        right: 0;
                        .title{
                            font-size: get-vw-mobile(40px);
                        }

                        .txt{
                            font-size: get-vw-mobile(55px);
                        }
                    }
                }
            }
        }

        // 上方背景圖
        &bg{
            &Index,
            &Main{
                &::before{
                    content: "";
                    max-width: 100%;
                    @include setBackSet(top,100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
            }

            // 首頁的上方背景圖
            &Index{
                max-width: 100%;
                max-height: 100%;
                background-image: url('../images/bannerBg_index.jpg');

                @include setMinRwd{
                    @include setSize(1920px,720px);
                }

                @include setWidthRwd(1150px,1289px){
                    height: 30em;
                }
            }

            // 內頁的上方梯形圖 
            &Trapezoidal{
                position: absolute;
                top: 0;
                right: 0;
                z-index: 4;
                mix-blend-mode: luminosity;
                opacity: 0.25;

                @include setSize(567px,447px);
                max-width: 100%;
                max-height: 100%;
                pointer-events: none;
            }

            // 內頁的上方背景圖
            &Main{
                position: absolute;
                z-index: -1;
                @include setSize(100%,9em);
                max-width: 100%;
                max-height: 100%;
                background-image: url('../images/bannerBg_main.jpg');

                @include setMinRwd{
                    @include setSize(1920px,390px);
                }
                
                @include setMaxRwd{
                    height: 21em;
                }

                @include pad{
                    height: 14.5em;
                }

                @include mobile{
                    height: 8.5em;
                }
            }
        }

        // 內頁Banner區塊
        &.main{
            .swiper{
                &-slideInfo {
                    .txtInfo {
                        &::after {
                            width: 100%;
                            height: 1px;
                            right: -110%;
                        }
                        
                        .title{
                            @include fontSize(36px);
                            p {
                                color: #0b0a0a;
                            }
                        }
    
                        .txt {
                            line-height: 1.25;
                        }
                    }

                    @include setMaxRwd{
                        .txtInfo {
                            transform: translateY(-45%);
                            .title{
                                @include fontSize(30px);
                            }
                            .txt {
                                @include fontSize(30px);
                            }
                        }
                    }

                    @include mobile{
                        .imgInfo {
                            img{
                                height: 5.25em; 
                                @include imgObject(cover,center);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                padding-bottom: 1em;
            }
        }
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}