@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
a, body {
  color: #222222;
  font-size: 18px;
  -webkit-text-stroke: 0.1px;
  line-height: 1.65;
  font-weight: 400;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.Prod-unreset dl, .dowload-list li, .dowload-item, .card-wrap, .form-group-list, .pagination-wrap, .content-nav-dropdown-container, .title-mainWrap, .mainContent, footer .footer-info-wrap, footer .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.news-title, .inMain-wrap, .card-time, .btn-wrap, .radio_style, .checkbox_style, .swiper-effects, .content-item, .heading-title, .page-title-content, footer .footer-icon, .pageloading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}
@media (max-width: 1600px) {
  body {
    font-size: 18px;
  }
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #015b15;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #015b15;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #015b15;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250, .nav-tabs.main {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.pageloading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s 0.2s;
  z-index: 10000;
}
.pageloading .loading {
  width: 200px;
  height: 8px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.pageloading .loading .bar {
  width: 0%;
  height: 100%;
  background-color: #015b15;
}
.pageloading.compelet {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

@keyframes jumping {
  50% {
    top: 0;
  }
  100% {
    top: -50px;
  }
}
@keyframes eyemove {
  0%, 10% {
    transform: translateX(50%);
  }
  90%, 100% {
    transform: translateX(-50%);
  }
}
.letter {
  float: left;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
}

.letter-holder .letter {
  animation-name: loadingF;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.53s;
}
.l-3 {
  animation-delay: 0.58s;
}
.l-4 {
  animation-delay: 0.63s;
}
.l-5 {
  animation-delay: 0.69s;
}
.l-6 {
  animation-delay: 0.74s;
}
.l-7 {
  animation-delay: 0.79s;
}
.l-8 {
  animation-delay: 0.84s;
}
.l-9 {
  animation-delay: 0.89s;
}
.l-10 {
  animation-delay: 0.94s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-loadHide {
  display: none !important;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/* Set the color of the icon */
svg path,
svg rect {
  fill: #015b15;
}

/* #Navigation
================================================== */
.start-header {
  padding-top: 5px;
  opacity: 1;
  transform: translateY(0);
  z-index: 2;
}
@media (min-width: 1025px) {
  .start-header .container {
    max-width: 1760px;
    margin-right: 1em;
  }
}
.start-header .container img {
  margin: 0;
}
@media (max-width: 1024px) {
  .start-header {
    z-index: 9999;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu {
  border: #a6fed9 1px solid;
  z-index: 3;
}
.dropdown-menu.show {
  animation: fadeIn 0.3s alternate;
}

.nav-link {
  font-family: "Noto Sans TC";
  font-size: 22px;
  font-size: 1.375rem;
  padding: 25px 20px !important;
}
.nav-link, .nav-link:hover, .nav-link.active {
  color: #fff !important;
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media (max-width: 1024px) {
  .nav-link {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .nav-link {
    padding-top: 5px !important;
  }
}

.nav-item {
  margin-left: 5px;
}
.nav-item img {
  margin: 0;
}
.nav-item-link,
.nav-item-link .nav-link {
  position: relative;
}
.nav-item-link .nav-link ~ .dropdown-menu {
  border-radius: 0;
}
@media (min-width: 1020px) {
  .nav-item-link .nav-link::after {
    content: "";
    display: none;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 80%;
    left: 50%;
    margin-left: -0.15em;
  }
  .nav-item-link .nav-link:hover::after, .nav-item-link .nav-link.active::after {
    display: inline-block;
  }
  .nav-item-link .nav-link ~ .dropdown-menu {
    max-width: 160px;
    left: 50%;
    margin-left: -80px;
    top: 100%;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .nav-item-link .nav-link::after {
    top: 90%;
  }
}
@media (max-width: 810px) {
  .nav-item-link .nav-link ~ .dropdown-menu {
    border: none;
    margin-right: 18px;
    margin-left: 20px;
  }
}
@media (min-width: 1020px) {
  .nav-item-link:hover .nav-link::after {
    display: inline-block;
  }
  .nav-item-link:hover > .dropdown-menu {
    display: block;
  }
}
.nav-item-lang {
  display: flex;
  align-items: center;
  margin-left: 1.25em;
}
.nav-item-lang .nav-link {
  font-size: 16px;
  font-size: 1rem;
  color: #a6fed9 !important;
  line-height: 1.75;
  border: #a6fed9 1px solid;
  padding: 5px 22px 7px !important;
}
.nav-item-lang .dropdown-menu {
  min-width: 100%;
  border-radius: 0;
  padding: 0;
  top: 85%;
}
.nav-item-lang .dropdown-menu li:not(:last-child) {
  margin-bottom: 1px;
}
.nav-item-lang .dropdown-item {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.nav-item-lang .dropdown-item:hover, .nav-item-lang .dropdown-item:focus {
  background-color: #a6fed9;
  color: #595757;
}
@media (max-width: 810px) {
  .nav-item-lang {
    margin-top: 1.25em;
  }
  .nav-item-lang .nav-link {
    display: none;
  }
  .nav-item-lang .dropdown-menu {
    min-width: auto;
    width: calc(100% - 1.25em);
    border-radius: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  .nav-item-lang .dropdown-menu li {
    flex: 1;
    text-align: center;
  }
}

@media (min-width: 1025px) {
  .navbar-brand {
    position: absolute;
    top: 1.75em;
  }
}
@media (max-width: 1024px) {
  .navbar-brand {
    width: 10vw;
  }
}
@media (max-width: 810px) {
  .navbar-brand {
    width: 15vw;
  }
}
@media (max-width: 640px) {
  .navbar-brand {
    width: 20vw;
  }
}
.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #fff;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0px;
  top: auto;
  bottom: 10%;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none;
  position: fixed;
  left: 60%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-16px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block;
}
@media (max-width: 810px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #8c9f97;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
    min-height: 100%;
  }
  .navbar-collapse.show {
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1025px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #fff !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 15px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .fa {
  transform: rotate(180deg);
}

.dropdown-item {
  white-space: normal;
}

.icons {
  display: inline-flex;
  margin-left: auto;
}
.icons a {
  transition: all 0.2s ease-in-out;
  padding: 0.2rem 0.4rem;
  color: #ccc !important;
  text-decoration: none;
}
.icons a:hover {
  color: white;
  text-shadow: 0 0 30px white;
}

footer {
  color: #fff;
}
footer .wrapper {
  align-items: flex-start;
}
footer .footer-top, footer .footer-bottom {
  display: flex;
}
footer .footer-top {
  background-color: #202020;
  padding-top: 20px;
  padding-bottom: 15px;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  footer .footer-top {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }
}
@media (max-width: 1024px) {
  footer .footer-top {
    padding: 1em 0.25em;
  }
}
footer .footer-bottom {
  justify-content: center;
  background-color: #4A4A4A;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 100;
  line-height: 1.5;
}
footer .footer-bottom p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  footer .footer-bottom {
    display: block;
  }
}
footer .footer-info {
  font-size: 0.875em;
  font-weight: 100;
  padding-left: 10px;
  padding-top: 7px;
}
footer .footer-info p {
  margin-bottom: 0.5em;
}
footer .footer-info-left {
  display: flex;
  align-items: flex-start;
}
@media (min-width: 1025px) {
  footer .footer-info-left {
    align-items: center;
  }
}
@media (max-width: 1024px) {
  footer .footer-info {
    padding-top: 10px;
  }
}
footer .footer-title {
  margin-bottom: 0.25em;
  font-weight: 900;
}
@media (max-width: 1024px) {
  footer .footer-title {
    font-size: 2em;
  }
}
footer .footer-txt {
  font-size: 17px;
  font-size: 1.0625rem;
}
@media (min-width: 1025px) {
  footer .footer-txt-list {
    padding-right: 37.5px;
  }
}
@media (max-width: 640px) {
  footer .footer-txt-list {
    display: block;
    position: relative;
    padding-left: 1.5em;
  }
  footer .footer-txt-list .icon {
    position: absolute;
    left: 0;
    top: 4px;
  }
}
footer .footer-icon {
  justify-content: flex-end;
  margin-top: 0.5em;
}
footer .footer-iconLink {
  margin-right: 5px;
}
footer .footer-logo {
  display: flex;
  align-items: center;
}
footer .footer-logo img {
  display: block;
  max-width: 100%;
}
footer .footer-info-wrap {
  width: 100%;
}
footer .footer-info-wrap li {
  flex: auto;
}
@media (min-width: 1025px) {
  footer .footer-info-wrap li {
    margin: 0 1em;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-wrap {
    flex: auto;
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  footer .footer-info-wrap li {
    flex: auto;
    width: 100%;
  }
  footer .footer-info-wrap li + li {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-bottom {
    margin-top: 1.5em !important;
  }
  footer .footer-info-bottom .footer-icon,
footer .footer-info-bottom .footer-info,
footer .footer-info-bottom .copyright p {
    justify-content: center;
  }
  footer .footer-info-bottom .copyright {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  footer {
    margin-bottom: 2.25em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
}
.iware,
.iware a {
  color: #fff;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1025px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #015b15;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    margin: 0;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.cookies-close {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.g-recaptcha iframe {
  height: auto !important;
}

.page-title, .page-title-content, .page-content {
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;
  background-repeat: repeat-y;
  background-image: url("../images/bg-page.jpg");
}

@media (min-width: 1020px) and (max-width: 1500px) {
  .page-content {
    padding: 1em;
  }
}
.page-title {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 1.5em 0;
  z-index: 3;
  width: 100%;
  max-width: 65em;
  text-align: center;
}
.page-title-content {
  justify-content: center;
}
.page-title-content.main {
  justify-content: space-between;
  padding-top: 1em;
}
@media (max-width: 1024px) {
  .page-title-content.main {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0;
  }
}
@media (min-width: 1025px) {
  .page-title {
    top: -1.5em;
    padding-left: 3em;
    padding-right: 3em;
  }
}

.in-box {
  overflow: hidden;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.lh1-45 {
  line-height: 1.45;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1025px) {
  .mainContent-info {
    width: 290px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 290px);
    margin-left: auto;
    padding-left: 85px;
    padding-right: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .mainContent-info {
    width: 260px;
  }
  .mainContent-info ~ .mainContent-list {
    padding-left: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent-info .headingLink-title,
.mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 1025px) {
  .mainContent-list {
    padding-top: 0;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .mainContent {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.headingLink-title {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  line-height: 1.3;
  margin-bottom: 1px;
  background-color: #7eba2e;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0.65em 1em 0.65em 1.25em;
}
.headingLink-title .ch {
  font-size: 23px;
  font-size: 1.4375rem;
  color: #fff;
  margin-bottom: 0;
}
.headingLink-title .en {
  font-size: 11px;
  font-size: 0.6875rem;
  color: #bedd95;
  padding-left: 10px;
  font-family: Helvetica;
}

.link-wrap ul > li {
  padding-left: 25px;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
  color: #252525;
  line-height: 1.5;
  padding: 0.75em 0rem 0.75rem 0;
}
.link-wrap ul > li > a::after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  background-color: #13ac6d;
  position: absolute;
  bottom: 0;
  left: 0;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  color: #015b15;
}
.link-wrap ul > li > ol {
  display: none;
  padding-top: 10px;
}
.link-wrap ul > li > ol.active {
  display: block;
}
.link-wrap ul > li > ol > li {
  margin-bottom: 1px;
}
.link-wrap ul > li > ol > li > a {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}
.link-wrap ul > li > ol > li > a::before {
  content: "\f067";
  font-family: FontAwesome;
  color: #404040;
  font-size: 13px;
  font-size: 0.8125rem;
  display: block;
  position: absolute;
  top: 1.05rem;
  right: 0;
}
.link-wrap ul > li > ol > li > a:hover, .link-wrap ul > li > ol > li > a.active {
  color: #015b15;
}
.link-wrap ul > li > ol > li > a:hover, .link-wrap ul > li > ol > li > a:hover::before, .link-wrap ul > li > ol > li > a.active, .link-wrap ul > li > ol > li > a.active::before {
  font-weight: 600;
}
.link-wrap ul > li > ol > li > a:hover::before, .link-wrap ul > li > ol > li > a.active::before {
  content: "\f068";
}
.link-wrap ul > li > ol .second_menu {
  display: none;
  padding-top: 10px;
  padding-bottom: 15px;
}
.link-wrap ul > li > ol .second_menu.active {
  display: block;
}
.link-wrap ul > li > ol .second_menu > li {
  padding-left: 0;
  padding-bottom: 0;
}
.link-wrap ul > li > ol .second_menu > li > a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #676767;
  padding-top: 7px;
  padding-bottom: 8px;
}
.link-wrap ul > li > ol .second_menu > li > a::before, .link-wrap ul > li > ol .second_menu > li > a::after {
  display: none;
}
.link-wrap ul > li > ol .second_menu > li > a:hover, .link-wrap ul > li > ol .second_menu > li > a:active {
  padding-left: 15px;
  color: #015b15;
}
@media (min-width: 1025px) {
  .link-wrap ul > li {
    padding-bottom: 1em;
  }
}
.link-wrap.pro ul > li > a::after {
  display: none;
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}

.main-banner {
  overflow: hidden;
  z-index: 1;
}
.main-banner img {
  width: 100%;
}
.main-banner-text .title-main {
  justify-content: space-around;
}
.main-banner-text .title-btn {
  text-align: center;
}
@media (min-width: 1025px) {
  .main-banner-text {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-banner-text.right {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    right: 5em;
  }
  .main-banner-text.right .title-main {
    writing-mode: vertical-rl;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 2.5em;
  }
  .main-banner-text.right .title-main p {
    letter-spacing: 4px;
    margin: 0;
  }
  .main-banner-text.right .title-main span.text-main {
    display: inline-block;
    line-height: 100%;
    padding: 0.15em 0.2em 0 0;
  }
  .main-banner-text.right .title-btn {
    order: -1;
    writing-mode: vertical-rl;
    margin: 2em 2em 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-banner-text.right .title-btn .btn {
    font-size: 24px;
    font-size: 1.5rem;
    padding: 1.3em 0.55em 1em !important;
    border-color: #000;
    letter-spacing: 4px;
  }
  .main-banner-text.right .title-btn .btn:hover {
    border-color: #015b15;
  }
  .main-banner-text.middle .title-main {
    line-height: 1.5;
  }
  .main-banner-text.middle .btn-wrap {
    margin-top: 3em;
  }
}
@media (max-width: 1024px) {
  .main-banner-text * {
    color: #000 !important;
  }
  .main-banner-text *::before {
    background-color: #000 !important;
  }
  .main-banner-text .btn {
    border-color: #000 !important;
  }
}

.text-mainRadius, .content-item, .img-mainRadius {
  border-radius: 15px !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.borderMain, .borderSmall {
  border: #222222 1px solid !important;
}
.borderIn {
  border: 1px solid #d2d2d2;
}
.borderMainCont {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  background-color: #6ebe77;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
}
.borderNone {
  border: none !important;
}
.borderNoneTop {
  border-top: none !important;
}
.borderNoneBottom {
  border-bottom: none !important;
}
.borderSmall {
  background-color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 2.5rem;
}

.boxShadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.boxShadow_p20 {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.06) !important;
}

.font-lg {
  font-size: 52px;
  font-size: 3.25rem;
}
.font-md {
  font-size: 44px;
  font-size: 2.75rem;
}
.font-sm {
  font-size: 36px;
  font-size: 2.25rem;
}
.font-sms {
  font-size: 30px;
  font-size: 1.875rem;
}
.font-xs, .bg-item {
  font-size: 28px;
  font-size: 1.75rem;
}
.font-xsl {
  font-size: 26px;
  font-size: 1.625rem;
}
.font-xxs {
  font-size: 24px;
  font-size: 1.5rem;
}
.font-main {
  font-size: 22px;
  font-size: 1.375rem;
}
.font-defalut {
  font-size: 20px;
  font-size: 1.25rem;
}
.font-small {
  font-size: 18px;
  font-size: 1.125rem;
}

.fw-900, .Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after, .title-ch, .link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  font-weight: 900 !important;
}

.font-tensentype {
  font-family: "tensentype-ruihei", sans-serif;
}

.text-main {
  color: #015b15 !important;
}
.text-base {
  color: #222222 !important;
}
.text-underLine {
  text-decoration: underline;
}
.text-link {
  color: #1d2088;
}
.text-normal {
  color: #5d5d5e;
}

.bg-intro {
  background-color: #eeeae9;
}
.bg-second {
  background-color: #eeeeee !important;
}
.bg-content {
  background-color: #ECECEC;
}
.bg-item {
  background-color: #353535;
  color: #fff;
  padding: 0.5em 1.25em;
}
@media (max-width: 1024px) {
  .bg-item {
    font-weight: lighter;
    font-size: 20px !important;
    padding: 0.25em 0.75em;
  }
}
.bg-style01 {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/bg-style01.jpg");
}
.bg-contentHelp {
  background-color: #e0e0e0;
}
.bg-contentEee {
  background-color: #eeeeee;
}

.flex-none {
  flex: none !important;
}
.flex-auto {
  flex: auto !important;
}

.title-mainHeadingPage {
  text-align: right;
  font-weight: 900;
  line-height: 1.05;
}
.title-mainHeadingPage p {
  margin-bottom: 0;
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .title-mainHeadingPage {
    margin-right: 100px;
  }
}
@media (max-width: 810px) {
  .title-mainHeadingPage {
    padding-right: 3em;
  }
}
.title-mainWrap {
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .title-mainWrap {
    justify-content: flex-end;
  }
}
.title-en {
  color: #d8d7d8;
  font-size: 75px;
  font-size: 4.6875rem;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .title-en {
    font-size: 10.9375vw;
  }
}
.title-ch {
  position: relative;
  color: #595757;
  font-size: 48px;
  font-size: 3rem;
  font-family: "tensentype-ruihei", sans-serif;
}
.title-ch::after {
  content: "";
  width: 85px;
  height: 15px;
  background-color: #181818;
  position: absolute;
  right: -100px;
  bottom: 5px;
  transform: skewX(-15deg);
}
@media (max-width: 1024px) {
  .title-ch {
    font-size: 4.375vw;
  }
  .title-ch::after {
    width: 60px;
    right: -75px;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-inmain {
  justify-content: space-between;
  align-items: center;
}

.heading-title {
  position: relative;
  width: 100%;
  color: #000;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  background: linear-gradient(55deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 95%, #f5f5f5 95%, #f5f5f5 95%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 12px 20px 10px;
}
.heading-title .title {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #fff;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .heading-title {
    background: linear-gradient(55deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 90%, #f5f5f5 90%, #f5f5f5 90%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .heading-title .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.styleLine-rightMiddle {
  position: relative;
  display: flex;
  align-items: center;
}
.styleLine-rightMiddle * {
  flex: none;
  margin-bottom: 0;
}
.styleLine-rightMiddle::after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
.styleLine-under {
  position: relative;
  text-align: center;
  padding-bottom: 0.5em;
}
.styleLine-under::before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  background-color: #000;
}
.styleLine-verticle {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.styleLine-verticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 30px;
  background-color: #000;
  margin-bottom: 1em;
}
.styleLine-verticle.white::before {
  background-color: #fff;
}
.styleLine-letTop {
  position: relative;
  padding-top: 0.5em;
  font-size: 22px;
  font-size: 1.375rem;
}
.styleLine-letTop * {
  margin-bottom: 0;
}
.styleLine-letTop::after {
  content: " ";
  display: block;
  width: 40px;
  height: 4px;
  background-color: #015b15;
  position: absolute;
  top: 0;
  left: 0;
}
.styleLine-letVerticle {
  position: relative;
  padding-left: 15px;
}
.styleLine-letVerticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #c9c9c9;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}
.styleLine-centerMiddle {
  font-size: 30px;
  font-size: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.styleLine-centerMiddle::before, .styleLine-centerMiddle::after {
  content: " ";
  display: block;
  border-bottom: 1px solid #aaa;
  flex: 1;
  margin: 0 1em;
}
.styleLine-centerMiddle p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .styleLine-centerMiddle {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.style_caretRight {
  position: relative;
  padding: 10px 0 10px 30px;
}
.style_caretRight:not(:last-child) {
  border-bottom: 1px dashed #fff;
}
.style_caretRight::before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: 1.25em;
  position: absolute;
  left: 0;
  top: 0.3em;
}

.block-title-box {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  padding-left: 0.5em;
  font-weight: 500;
  z-index: 2;
}
.block-title-box .txt {
  flex: none;
  margin-bottom: 0;
}
.block-title-box::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: #015b15 2px solid;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -15px;
}
.block-title-box::after {
  content: "";
  display: inline-block;
  width: 130px;
  height: 1px;
  background-color: #015b15;
  margin-left: 0.5em;
}

.titleStyle {
  display: block;
  width: 100%;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d6d6d6;
  position: absolute;
  top: 0px;
  z-index: 1;
}
.titleStyle::after {
  content: "";
  display: block;
  width: 30px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon_style.png");
  background-color: #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
}
.titleStyle.gray::after {
  background-color: #eee;
}

.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}
.style__dot {
  position: relative;
  padding-left: 2rem !important;
  line-height: 1.5;
}
.style__dot:not(:last-of-type) {
  margin-bottom: 0.5em;
}
.style__dot::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 100%;
  position: absolute;
  left: 15px;
  top: 10px;
  transform: translateY(0);
}

.content-item {
  position: relative;
  justify-content: center;
  border: #dddddd 1px solid;
  overflow: hidden;
  transition: 0.25s;
  height: 0;
  padding: 1.65em 0.9em 46%;
}
.content-item .num {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  color: #353535;
  line-height: 1.25;
  background-color: #eeeeee;
  text-align: center;
  padding: 10px 15px;
  border-radius: 12px;
  position: absolute;
  left: -7px;
  top: -3px;
}
.content-item .info {
  text-align: center;
  color: #353535;
}
@media (max-width: 1024px) {
  .content-item .info {
    padding: 1em 0.5em;
  }
}
.content-item .title {
  font-weight: 900;
  font-size: 3.75vw;
}
@media (min-width: 1025px) {
  .content-item .title {
    font-size: 1.8947368421vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .title {
    font-size: 2.34375vw;
  }
}
.content-item .txt {
  margin-bottom: 0;
  font-size: 3.125vw;
  line-height: 1.3;
}
@media (min-width: 1025px) {
  .content-item .txt {
    font-size: 0.8947368421vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-item .txt {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .txt {
    font-size: 1.3157894737vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .txt {
    font-size: 2.5vw;
  }
}
@media (min-width: 1025px) {
  .content-item {
    background-color: #fff;
  }
}
@media (min-width: 800px) and (max-width: 1600px) {
  .content-item {
    height: auto;
    min-height: 8rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .content-item {
    height: auto;
    border-radius: 10px;
    padding: 0 !important;
  }
}
.content-link {
  position: relative;
  display: block;
  width: 100%;
}
@media (min-width: 1025px) {
  .content-link::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -9px;
    transition: 0.25s;
    z-index: 2;
  }
  .content-link::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #015b15;
    border-radius: 50%;
    position: absolute;
    left: calc(100% + 1.1em);
    top: 50%;
    margin-top: -3.5px;
  }
}
@media (min-width: 1025px) and (max-width: 1024px) {
  .content-link::after {
    display: none;
  }
}
@media (min-width: 1025px) {
  .content-link:hover::before, .content-link.active::before {
    background-image: url("../images/content-link-active.png");
  }
  .content-link:hover .content-item, .content-link.active .content-item {
    border-color: #015b15;
  }
  .content-link:hover .content-item .title, .content-link.active .content-item .title {
    color: #015b15;
  }
}
.content-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.content-nav-list {
  flex: 1;
}
.content-nav-list:last-child .content-link::after {
  display: none;
}
@media (min-width: 1025px) {
  .content-nav-list + .content-nav-list {
    padding-left: 2.5em;
  }
}
@media (max-width: 1024px) {
  .content-nav-list {
    flex: 0 0 calc(50% - 1em);
    margin: 0 0.5em 1.5em;
  }
}
.content-nav.small {
  justify-content: center;
}
.content-nav.small .content-nav-list {
  position: relative;
  flex: none;
}
.content-nav.small .content-link::after {
  left: calc(100% + 1em);
}
.content-nav.small .content-item {
  min-height: auto;
  height: auto;
  padding: 1.15em 2em;
}
.content-nav.small .content-item .title {
  font-size: 3.75vw;
  font-weight: 500;
}
@media (min-width: 1025px) {
  .content-nav.small .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .content-nav.small .content-item .title {
    font-size: 1.4736842105vw;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .content-nav.small .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (max-width: 1024px) {
  .content-nav.small .content-item .title br {
    display: none;
  }
}
.content-nav.small-noBorder .content-nav-list {
  flex: none;
}
.content-nav.small-noBorder .content-item {
  border: none;
}
.content-nav.small-noBorder .content-item .title {
  color: #606060;
  font-size: 3.4375vw;
}
@media (min-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.content-nav.small-noBorder .content-link::before {
  width: 72px;
  height: 10px;
  bottom: 0;
  margin-left: -36px;
  transition: 0s;
}
.content-nav.small-noBorder .content-link::after {
  display: none;
}
.content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
  background-image: url("../images/content-line-active.png");
  bottom: 3px;
}
.content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
  display: block !important;
  width: 100%;
  height: 2px;
  border-radius: 0;
  left: 0;
  bottom: 10px;
  top: auto;
}
@media (max-width: 1024px) {
  .content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
    bottom: 0;
  }
  .content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
    bottom: 7px;
  }
}
@media (min-width: 1025px) {
  .content-nav.small.small-xs .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.content-nav.small.default .content-nav-list {
  flex: 1 0 auto !important;
  max-width: 100% !important;
}
@media (min-width: 1025px) {
  .content-nav.small.default .content-item {
    min-height: 100px;
  }
}
@media (max-width: 1024px) {
  .content-nav.small.default .content-nav-list {
    margin: 5px;
  }
  .content-nav.small.default .content-item .info {
    padding: 0.5em 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small .content-item {
    min-height: auto;
  }
}
@media (max-width: 1024px) {
  .content-nav.small {
    justify-content: space-around;
    margin-bottom: 1em;
  }
  .content-nav.small .content-link:hover::before, .content-nav.small .content-link:hover::after, .content-nav.small .content-link.active::before, .content-nav.small .content-link.active::after {
    display: none !important;
  }
  .content-nav.small .content-link:hover .title, .content-nav.small .content-link.active .title {
    color: #000;
  }
}
@media (max-width: 1024px) {
  .content-nav {
    justify-content: flex-start !important;
    margin-bottom: 0 !important;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .content-nav-list {
    flex: 0 0 calc(33.3333333333% - 4px) !important;
    max-width: calc(33.3333333333% - 4px) !important;
    margin: 0 2px 5px 2px;
    background: #eee;
  }
  .content-nav-list * {
    border: none;
  }
}
.content-nav-dropdown {
  min-width: 100vw;
  padding: 1em 0.25em;
  margin-bottom: 1.5em;
  background: #E5E5E5;
  position: absolute;
  left: 0.75em;
  top: 7em;
  z-index: 1;
}
.content-nav-dropdown::before {
  content: "";
  display: block;
  width: 1000%;
  height: 100%;
  background: #E5E5E5;
  position: absolute;
  left: -500%;
  top: 0;
  z-index: 0;
}
.content-nav-dropdown-container {
  position: relative;
  z-index: 1;
  width: 100%;
}
.content-nav-dropdown-container li {
  position: relative;
  padding: 0 2em;
}
.content-nav-dropdown-container li::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #5F5F5F;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -7.5px;
}
@media (max-width: 1024px) {
  .content-nav-dropdown-container li {
    padding-left: 1em;
    padding-right: 1em;
  }
  .content-nav-dropdown-container li::after {
    margin-top: -4.5px;
  }
}
.content-nav-dropdown-container a {
  font-size: 3.75vw;
  line-height: 1.5;
  color: #6c757d;
}
.content-nav-dropdown-container a:hover, .content-nav-dropdown-container a.active {
  color: #2B2B2B;
}
@media (min-width: 1025px) {
  .content-nav-dropdown-container a {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (max-width: 1024px) {
  .content-nav-dropdown {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 0.25em;
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }
  .content-nav-dropdown-container a {
    font-size: 3.59375vw;
  }
}
.content-nav-dropdown.normal {
  position: relative;
  top: 0;
  left: 0;
  min-width: auto;
  overflow: hidden;
}
.content-nav-dropdown.normal .content-nav-dropdown-container {
  justify-content: center;
}
@media (max-width: 1024px) {
  .content-link.active::before, .content-link.active::after {
    display: none !important;
  }
}

.tag-content {
  text-align: center;
}
.tag-content a {
  border-radius: 25px;
  margin-left: 6px;
  margin-right: 6px;
}
.tag-content a.active {
  border-color: #015b15;
  background-color: #015b15;
  color: #fff;
}
@media (max-width: 1024px) {
  .tag-content a {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 3.125vw;
  }
}

.select_style::after {
  content: "\f0d7";
  font-family: "fontAwesome";
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  height: 34px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.count_style {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 3px;
}
.count_style .item .form-control {
  height: auto;
  padding: 0;
  background-color: transparent;
  text-align: center;
}
.count_style .add,
.count_style .minus {
  width: 30px;
  flex: none;
  text-align: center;
  padding: 0;
}
.count_style .add.btn .fa,
.count_style .minus.btn .fa {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 !important;
  color: #222222;
}

.nav-tabs.main {
  border-top: #323232 1px solid;
  border-bottom: #c1c1c1 1px solid;
  margin-top: 2em;
}
@media (max-width: 1024px) {
  .nav-tabs.main {
    justify-content: space-around;
  }
}
.nav-tabs.main .nav-item {
  text-align: center;
  margin: 0 2em;
}
.nav-tabs.main .nav-item::after {
  display: none;
}
@media (max-width: 1024px) {
  .nav-tabs.main .nav-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.nav-tabs.main .nav-link {
  font-size: 20px;
  font-size: 1.25rem;
  background-color: #fff;
  color: #000 !important;
  border-radius: 0;
  border-color: transparent;
  margin-bottom: 0px;
  padding: 1em 0.25em !important;
}
.nav-tabs.main .nav-link:hover, .nav-tabs.main .nav-link.active {
  border-bottom: #212020 2px solid;
}
@media (max-width: 1024px) {
  .nav-tabs.main .nav-link {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 0.5em 0.25em !important;
  }
}
@media (min-width: 1025px) {
  .nav-tabs.main, .nav-tabs.main + .tab-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  display: block;
  width: 30px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px;
  background-image: url("../images/icon-arrow-right01.png");
  padding-top: 1.7em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: black;
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 3em;
  }
}
.breadcrum-wrap .icon {
  margin-top: -3px;
}
@media (max-width: 1024px) {
  .breadcrum-wrap {
    padding-left: 10px;
  }
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 45px;
  height: 45px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 45px;
  color: #6e6d6d;
  font-family: "Roboto";
  background-color: transparent;
  border-color: #7d7d7d;
  border-radius: 0;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #13ac6d;
  border-color: #13ac6d;
}
.pagination-wrap .pagination-select .select_style {
  width: 100%;
}
.pagination-wrap .pagination-select .select_style::after {
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1025px) {
  .pagination-wrap .pagination-select {
    width: 60px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap .pagination-select {
    display: flex;
    justify-content: center;
    width: 175px;
  }
}
.pagination-wrap .fa {
  font-size: 1.2em;
}
@media (max-width: 1024px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #353535;
}
.tag-item.tag {
  color: #4D4D4D;
  background-color: #e5e5e5;
  line-height: 25px;
  padding: 0.5em 1em;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-size: 0.875rem;
}
@media (min-width: 1025px) {
  .tag-item.tag {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 8px 24px;
  }
}
.tag-item.main {
  background-color: #015b15;
  border-radius: 30px;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new {
  border-radius: 30px;
  background-color: #7eba2e;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new .txt {
  color: #ffe42d;
  font-size: 14px;
  font-size: 0.875rem;
  padding-left: 5px;
}
.tag-item.default {
  border: #dbdbdb 1px solid;
  background-color: #fff;
  border-radius: 10px;
  color: #5f5f5f;
  font-size: 16px !important;
  padding: 6px 12px;
}
.tag-item.dark {
  background-color: #353535;
  color: #fff;
}
.tag-item.white {
  display: inline-flex;
  align-items: center;
  border: #fff 1px solid;
  background-color: #fff;
  font-size: 16px !important;
  padding: 6px 12px;
  line-height: 1.5;
}
.tag-item.white .icon {
  margin-right: 10px;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-effects {
  position: relative;
  top: 0.5em;
}
.swiper-effects .swiper-pagination,
.swiper-effects .swiper-button-prev,
.swiper-effects .swiper-button-next {
  position: static;
  width: auto;
  margin: 0;
}
.swiper-effects .swiper-pagination {
  padding: 0 25px;
  font-family: "Roboto";
  color: #bbbbbb;
}
.swiper-effects .swiper-pagination .swiper-pagination-current {
  color: #222222;
}
.swiper-effects .swiper-button-prev::after,
.swiper-effects .swiper-button-next::after {
  font-size: 20px;
  font-size: 1.25rem;
  color: #222222;
}

.cataTxt {
  font-size: 16px;
  font-size: 1rem;
  color: #0c3766;
  line-height: 1.5;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 2.5px;
}
@media (max-width: 1024px) {
  .cataTxt {
    padding-top: 0;
    padding-bottom: 5px;
  }
}
.cataList {
  width: 100%;
  padding: 1em 1em 0.5em;
  z-index: 0;
  cursor: pointer;
}
@media (min-width: 1025px) {
  .cataList {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  .cataList {
    padding: 0.5em;
    padding-bottom: 0.25em;
  }
}
.cataList::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: #e8e8e8 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cataList-wrap:hover .cataList::before {
  box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.1) !important;
}
.cataListImg {
  margin-bottom: 0.25rem;
  overflow: hidden;
  height: 9em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cataListImg img {
  max-height: 100%;
}
@media (max-width: 810px) {
  .cataListImg {
    height: 29vw;
    align-items: flex-start;
  }
}

.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  background-color: #E5E5E5;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  color: #fff;
  opacity: 0;
}
.checkbox_style > input:checked + label::before {
  background-color: #015b15;
  border-color: #015b15;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 6px;
}
.radio_style input:checked + label::after {
  background-color: #015b15;
}
.form-group-list .item {
  flex: 1;
}
@media (max-width: 1024px) {
  .form-group-list .item {
    padding-right: 10px;
  }
}

.accordion .btn {
  position: relative;
  font-size: 4.6875vw;
  font-weight: 600;
  color: #353535;
  text-decoration: none !important;
  padding-left: 0;
}
.accordion .btn::after {
  content: "\f068";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 10px;
}
.accordion .btn.collapsed::after {
  content: "\f067";
}
@media (min-width: 1025px) {
  .accordion .btn {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.accordion .card {
  border: none;
}
.accordion .card > .card-header {
  background: transparent;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  margin-bottom: 0;
}
.accordion .card .card-body {
  line-height: 150%;
}
@media (min-width: 1025px) {
  .accordion .card .card-body {
    padding-left: 2.5em;
  }
}

@media (min-width: 1025px) {
  .pageBlog-content {
    padding-top: 4em;
  }
  .pageBlog-content .pageTitle {
    width: 26em;
    padding-left: 1em;
  }
  .pageBlog-content .pageIimg {
    flex: 1;
  }
  .pageBlog-content .btn {
    padding-left: 1.75em !important;
    padding-right: 1.75em !important;
    padding-bottom: 0.65em !important;
  }
  .pageBlog-content .ser-contentIntro-item-list {
    margin-bottom: 4em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) {
    margin-right: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) .pageTitle {
    padding-right: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) {
    margin-right: auto;
    margin-left: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageImg {
    order: -1;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageTitle {
    padding-left: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:last-of-type {
    margin-bottom: 0;
    padding-bottom: 4em;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .pageBlog-content .ser-contentIntro-item-list .pageTitle {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}
@media (max-width: 1024px) {
  .pageBlog-content {
    padding: 1em 1em 0;
  }
  .pageBlog-content .pageImg {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.min-80 {
  min-width: 80px;
  flex: none !important;
  width: auto;
}

.alpha-target {
  mask-image: url("../images/alpha-square.png");
  mask-mode: alpha;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
  min-height: 50px;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1025px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  background-color: #015b15;
  border-color: #015b15;
  color: #fff;
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  background-color: #13ac6d !important;
  border-color: #13ac6d !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0.5em 1.25em;
  border-radius: 0px;
  font-weight: 100;
}
.btn .fa {
  font-weight: 100;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 6px;
}
.btn .icon {
  vertical-align: top;
  margin-top: -2px;
  margin-left: 3px;
  margin-right: 3px;
}
.btn-white {
  color: #fff !important;
}
.btn-wrap {
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (min-width: 1025px) {
  .btn-wrap {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
@media (max-width: 640px) {
  .btn-wrap > li {
    flex: none;
    width: 100%;
  }
  .btn-wrap > li + li {
    margin-top: 1em;
  }
}
.btn-txt-lg {
  font-size: 1.35rem;
}

.more-btn-wrap .btn {
  font-size: 20px;
  font-size: 1.25rem;
  border-bottom: #015b15 2px solid;
}
.more-btn-wrap .btn-white {
  border-bottom: #fff 2px solid;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: #7d7d7d;
}

.card {
  flex-grow: 1;
  margin-left: 0.25em;
  margin-right: 0.25em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1025px) {
  .card__2 {
    flex: 0 0 calc(50% - 0.5em);
    max-width: calc(50% - 0.5em);
  }
}
@media (min-width: 1025px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 0.5em);
    max-width: calc(33.3333333333% - 0.5em);
  }
}
@media (min-width: 1025px) {
  .card__4 {
    flex: 0 0 calc(25% - 0.5em);
    max-width: calc(25% - 0.5em);
  }
}
@media (max-width: 1024px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 0.5em) !important;
  max-width: calc(100% - 0.5em) !important;
}
@media (min-width: 1025px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time {
  font-weight: bold;
}
.card-time .date {
  color: #015b15;
  font-size: 24px;
  font-size: 1.5rem;
  padding-right: 10px;
}
.card-time .date + .year::before {
  content: " / ";
  display: inline-block;
  padding-right: 5px;
}
.card-time .year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding-bottom: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #595757;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: 900;
  color: #aaaaaa;
  padding-left: 0 !important;
  margin-top: 2em;
}
.card-btn::after {
  max-width: 200px;
  background-color: #aaaaaa;
  margin-left: 10px;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1025px) {
  .card-wrap-indexNews {
    padding-left: 50px;
    padding-right: 50px;
  }
  .card-wrap-indexNews-img {
    max-width: 515px;
  }
  .card-wrap-indexNews-list {
    max-width: calc(100% - 515px);
    padding-left: 60px;
  }
  .card-wrap-indexNews-list .card-caption {
    padding-top: 0;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .card-wrap-indexNews-img {
    max-width: 350px;
  }
  .card-wrap-indexNews-list {
    max-width: calc(100% - 350px);
    padding-left: 10px;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .card-wrap-indexNews-img {
    max-width: 400px;
  }
  .card-wrap-indexNews-list {
    max-width: calc(100% - 400px);
    padding-left: 20px;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexNews .card-caption {
    padding-left: 0;
  }
  .card-wrap-indexNews-img .card {
    flex: 0 0 calc(100% - 1em);
    max-width: calc(100% - 1em);
    margin-bottom: 0;
  }
}
.card-wrap-indexApplication .card {
  background-color: transparent;
}
.card-wrap-indexApplication .card-title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #13ac6d;
}
@media (max-width: 1024px) {
  .card-wrap-indexApplication .card-title {
    font-size: 20px;
    font-size: 1.25rem;
    margin-bottom: 0.25em;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexApplication .card-text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.1;
  }
}
.card-wrap-indexApplication .card-caption {
  line-height: 0.5;
  position: absolute;
  bottom: -2.65em;
  left: 2em;
  width: calc(100% - 4em);
}
@media (min-width: 1025px) {
  .card-wrap-indexApplication .card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-wrap-indexApplication .card-img-top::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0s;
  }
  .card-wrap-indexApplication .card:hover .card-img-top::before {
    background: rgba(19, 44, 24, 0.7);
    opacity: 1;
    transition: 0.25s;
  }
  .card-wrap-indexApplication .card:hover .card-caption {
    width: calc(100% - 40px);
    top: 0;
    left: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    overflow: hidden;
  }
  .card-wrap-indexApplication .card:hover .card-title,
.card-wrap-indexApplication .card:hover .card-text {
    position: relative;
    z-index: 1;
    color: #fff;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexApplication .card {
    margin-bottom: 1em !important;
  }
  .card-wrap-indexApplication .card-caption {
    width: calc(100% - 1em);
    position: static;
    margin-top: 0;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexApplication {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.card-wrap-indexApplicationTop {
  align-items: flex-start;
}
@media (min-width: 1025px) {
  .card-wrap-indexApplicationTop .card:nth-child(2) {
    margin-top: 40px;
  }
  .card-wrap-indexApplicationTop .card:nth-child(2)::after {
    content: "";
    display: block;
    width: 300px;
    height: 60px;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    background-image: url("../images/alpha-trapezoidal.png");
    position: absolute;
    bottom: 0;
    left: 150px;
    z-index: -1;
    opacity: 0;
    transition: 0.25s;
  }
  .card-wrap-indexApplicationTop .card:nth-child(2):hover::after {
    left: 6%;
    opacity: 1;
  }
}
.card-wrap-indexApplicationTop .card:nth-child(3) {
  margin-top: 10px;
}
.card-wrap-indexApplicationTop .card:nth-child(3) .card-img-top::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 100px 0 0;
  border-color: #13ac6c transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1024px) {
  .card-wrap-indexApplicationTop .card:nth-child(3) .card-img-top::after {
    border-width: 50px 50px 0 0;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexApplicationBottom .card:first-child .card-img-top::after {
    content: "";
    display: block;
    width: 96%;
    height: 92%;
    border: #fff 1px solid;
    border-bottom-width: 0;
    position: absolute;
    top: 4%;
    left: 2%;
    z-index: 1;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-title {
    display: flex;
    align-items: center;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-title::before, .card-wrap-indexApplicationBottom .card:first-child .card-title::after {
    content: " ";
    display: block;
    border-bottom: 1px solid #fff;
    flex: 1;
    margin-top: -0.375em;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-title::before {
    flex: 0 0 20px;
    margin-right: 10px;
    margin-left: -2px;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-title::after {
    margin-right: -2px;
    margin-left: 10px;
  }
  .card-wrap-indexApplicationBottom .card:first-child .card-text {
    padding-left: 32px;
  }
  .card-wrap-indexApplicationBottom .card:first-child:hover .card-img-top::after {
    border-bottom-width: 1px;
  }
  .card-wrap-indexApplicationBottom .card:first-child:hover .card-title::before, .card-wrap-indexApplicationBottom .card:first-child:hover .card-title::after {
    display: none;
  }
  .card-wrap-indexApplicationBottom .card:first-child:hover .card-text {
    padding-left: 0;
  }
  .card-wrap-indexApplicationBottom .card:last-child::after {
    content: "";
    width: 223px;
    height: 181px;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    background-image: url("../images/cardImg-application05-item.png");
    position: absolute;
    bottom: -20%;
    right: -5%;
  }
}
@media (min-width: 1025px) and (min-width: 1020px) and (max-width: 1100px) {
  .card-wrap-indexApplicationBottom .card:last-child::after {
    width: 150px;
    height: 130px;
  }
}
@media (min-width: 1025px) and (max-width: 1024px) {
  .card-wrap-indexApplicationBottom .card:last-child::after {
    width: 100px;
    height: 75px;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexApplicationBottom {
    margin-top: 6em;
  }
}
@media (min-width: 1025px) {
  .card-wrap-index {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1024px) {
  .card {
    margin-bottom: 1em;
  }
  .card-time .date,
.card-time .year {
    margin-bottom: 0;
  }
  .card-title {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em 0;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.news .card-caption {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1025px) {
  .news .card {
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex: 0 0 calc(33.3333333333% - 3em);
    max-width: calc(33.3333333333% - 3em);
  }
  .news .card-img-top {
    margin-bottom: 1.5em;
  }
  .news .card-btn {
    margin-top: 1.25em;
  }
  .news .card__horizontal {
    background: #14aa6b; /* Old browsers */
    background: -moz-linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14aa6b", endColorstr="#f5f5f5",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 25px;
    margin-bottom: 50px;
  }
  .news .card__horizontal .card-img-top {
    flex: none;
    width: 510px;
    margin-right: 50px;
    margin-bottom: 0;
  }
  .news .card__horizontal .card-caption {
    padding-left: 0;
  }
  .news .card__horizontal .card-body {
    display: flex;
    flex-direction: column;
  }
  .news .card__horizontal .card-body .card-btn {
    margin-top: auto;
  }
}

@media (min-width: 1025px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1025px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}

.index-content .list {
  height: 100%;
}
.index-content .title {
  font-weight: 600;
}
.index-content .txt {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 3.59375vw;
}
@media (min-width: 1025px) {
  .index-content .txt {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.index-content .btn {
  padding: 0.7em 1.95em;
}
@media (max-width: 1024px) {
  .index-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .index-content .btn {
    line-height: 1.25;
    padding: 0.5em 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.index-pageAbout {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../images/index-about-bg.png");
  padding-top: 195px;
  margin-top: -100px;
  z-index: 1;
}
.index-pageAbout .title-mainHeadingPage p {
  color: #fff !important;
}
.index-pageAbout .title-mainHeadingPage .title-ch::after {
  background-color: #fff;
}
.index-pageAbout .title-mainHeadingPage .title-en {
  opacity: 0.25;
  margin-top: 10px;
}
@media (min-width: 1025px) {
  .index-pageAbout {
    margin-top: -120px;
    margin-bottom: -100px;
  }
  .index-pageAbout-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6em;
  }
  .index-pageAbout-item {
    flex: none;
    width: 560px;
  }
  .index-pageAbout-text {
    flex: 1;
    padding-left: 12%;
  }
}
@media (min-width: 1150px) and (max-width: 1500px) {
  .index-pageAbout {
    background-size: cover;
    padding-left: 3em;
    padding-right: 3em;
  }
  .index-pageAbout-text {
    padding-left: 5%;
  }
}
@media (min-width: 1381px) and (max-width: 1500px) {
  .index-pageAbout {
    margin-top: -242px;
    margin-bottom: -50px;
    padding-top: 265px;
  }
}
@media (min-width: 1290px) and (max-width: 1380px) {
  .index-pageAbout {
    margin-top: -215px;
  }
}
@media (min-width: 1150px) and (max-width: 1289px) {
  .index-pageAbout {
    margin-top: -240px;
    margin-bottom: 0;
    padding-top: 241px;
    padding-bottom: 241px;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .index-pageAbout {
    background-position: top;
    background-size: cover;
    margin-top: -200px;
  }
}
@media (max-width: 1024px) {
  .index-pageAbout {
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 120px;
  }
}
.index-pageApplication {
  z-index: 0;
}
.index-pageApplication::before {
  content: "";
  display: block;
  width: 120%;
  height: 128%;
  background-color: #eeeeee;
  position: absolute;
  top: -26%;
  left: -10%;
  transform: rotate(3deg);
  z-index: -1;
}
@media (max-width: 1024px) {
  .index-pageApplication::before {
    width: 100%;
    height: 121%;
    left: 0;
    transform: rotate(0deg);
  }
}
@media (min-width: 1025px) {
  .index-pageApplication {
    margin-bottom: 4em;
    padding-bottom: 70px;
  }
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 1050px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 11%;
  }
}
@media (min-width: 1025px) {
  .page-banner .bannerSwiper {
    padding-bottom: 3em;
  }
  .page-banner .bannerSwiper::before {
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
}
.page-banner .swiper-pagination-bullets {
  bottom: 0 !important;
}
@media (min-width: 1025px) {
  .page-banner .swiper-pagination-bullets {
    width: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: 1.5em;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    border: #fff 1px solid;
    padding: 0.5em;
    border-radius: 1em;
  }
}
@media (max-width: 1024px) {
  .page-banner .swiper-pagination-bullets {
    bottom: -1.5em !important;
  }
}
.page-banner .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #000;
}
.page-banner .swiper-pagination-bullet-active {
  background-color: #015b15 !important;
  border-radius: 0.75em !important;
}
@media (min-width: 1025px) {
  .page-banner .swiper-pagination-bullet {
    display: block;
    margin: 0.3em 0 !important;
    transition: height 200ms ease-in;
    background-color: #fff;
    opacity: 1;
  }
  .page-banner .swiper-pagination-bullet-active {
    height: 1.75em !important;
  }
}
@media (max-width: 1024px) {
  .page-banner .swiper-pagination-bullet-active {
    width: 1.75em !important;
    margin: 0 0.3em !important;
  }
}
.page-banner .swiper-slideInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: auto;
}
.page-banner .swiper-slideInfo .imgInfo {
  text-align: right;
  flex: 1;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .page-banner .swiper-slideInfo .imgInfo {
    padding-top: 1em;
    padding-right: 1.5em;
  }
}
@media (min-width: 1410px) and (max-width: 1500px) {
  .page-banner .swiper-slideInfo .imgInfo {
    padding-top: 6em;
  }
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .page-banner .swiper-slideInfo .imgInfo {
    flex: none;
    max-width: 60em;
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .page-banner .swiper-slideInfo .imgInfo {
    max-width: 68em;
    padding-top: 3em;
  }
}
.page-banner .swiper-slideInfo .txtInfo {
  text-align: right;
  position: absolute;
  top: 50%;
  right: 200px;
  padding-right: 2%;
  z-index: 1;
}
.page-banner .swiper-slideInfo .txtInfo::after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: -50%;
  top: 50%;
}
.page-banner .swiper-slideInfo .txtInfo p {
  margin-bottom: 0;
  color: #fff;
}
.page-banner .swiper-slideInfo .txtInfo .title {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
}
.page-banner .swiper-slideInfo .txtInfo .txt {
  font-size: 70px;
  font-size: 4.375rem;
  line-height: 1.5;
  font-family: "Alfa Slab One";
}
.page-banner .swiper-slideInfo .txtInfo .txt p {
  opacity: 0.27;
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .page-banner .swiper-slideInfo .txtInfo .title {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
@media (max-width: 810px) {
  .page-banner .swiper-slideInfo {
    flex-direction: column;
  }
  .page-banner .swiper-slideInfo .imgInfo {
    order: -1;
  }
  .page-banner .swiper-slideInfo .txtInfo {
    width: 100%;
    right: 0;
  }
  .page-banner .swiper-slideInfo .txtInfo .title {
    font-size: 6.25vw;
  }
  .page-banner .swiper-slideInfo .txtInfo .txt {
    font-size: 8.59375vw;
  }
}
.page-bannerbgIndex::before, .page-bannerbgMain::before {
  content: "";
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.page-bannerbgIndex {
  max-width: 100%;
  max-height: 100%;
  background-image: url("../images/bannerBg_index.jpg");
}
@media (min-width: 1025px) {
  .page-bannerbgIndex {
    width: 1920px;
    height: 720px;
  }
}
@media (min-width: 1150px) and (max-width: 1289px) {
  .page-bannerbgIndex {
    height: 30em;
  }
}
.page-bannerbgTrapezoidal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  mix-blend-mode: luminosity;
  opacity: 0.25;
  width: 567px;
  height: 447px;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}
.page-bannerbgMain {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 9em;
  max-width: 100%;
  max-height: 100%;
  background-image: url("../images/bannerBg_main.jpg");
}
@media (min-width: 1025px) {
  .page-bannerbgMain {
    width: 1920px;
    height: 390px;
  }
}
@media (max-width: 1024px) {
  .page-bannerbgMain {
    height: 21em;
  }
}
@media (max-width: 810px) {
  .page-bannerbgMain {
    height: 14.5em;
  }
}
@media (max-width: 640px) {
  .page-bannerbgMain {
    height: 8.5em;
  }
}
.page-banner.main .swiper-slideInfo .txtInfo::after {
  width: 100%;
  height: 1px;
  right: -110%;
}
.page-banner.main .swiper-slideInfo .txtInfo .title {
  font-size: 36px;
  font-size: 2.25rem;
}
.page-banner.main .swiper-slideInfo .txtInfo .title p {
  color: #0b0a0a;
}
.page-banner.main .swiper-slideInfo .txtInfo .txt {
  line-height: 1.25;
}
@media (max-width: 1024px) {
  .page-banner.main .swiper-slideInfo .txtInfo {
    transform: translateY(-45%);
  }
  .page-banner.main .swiper-slideInfo .txtInfo .title {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .page-banner.main .swiper-slideInfo .txtInfo .txt {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (max-width: 640px) {
  .page-banner.main .swiper-slideInfo .imgInfo img {
    height: 5.25em;
    object-fit: cover;
    object-position: center;
  }
}
@media (max-width: 1024px) {
  .page-banner.main {
    padding-bottom: 1em;
  }
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.inMain-gradient {
  background: #14aa6b; /* Old browsers */
}
@media (min-width: 1025px) {
  .inMain-gradient {
    background: -moz-linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14aa6b", endColorstr="#f5f5f5",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}
.inMain-wrap {
  padding: 1.25em;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 1025px) {
  .inMain-wrap {
    padding: 50px;
  }
}
.inMain-list {
  flex: 1;
}
@media (min-width: 1025px) {
  .inMain-list {
    padding-right: 20px;
  }
}
.inMain-title {
  font-size: 26px;
  font-size: 1.625rem;
}
@media (min-width: 1025px) {
  .inMain-title {
    color: #fff;
    margin-bottom: 1em;
    font-size: 48px;
    font-size: 3rem;
  }
}
@media (min-width: 1025px) {
  .inMain-txt {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (max-width: 1024px) {
  .inMain-img {
    margin-bottom: 10px;
  }
}
@media (max-width: 810px) {
  .inMain-img {
    order: -1;
  }
}
.inMain-form {
  flex: 0 0 740px;
  max-width: 740px;
}
@media (max-width: 1024px) {
  .inMain-form {
    flex: none;
    width: 100%;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .inMain-img {
    flex: 0 0 20em;
    margin: 0 10px;
  }
}

@media (min-width: 1025px) {
  .news-box {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
.news-title {
  width: 100%;
  height: height;
  background: #14aa6b; /* Old browsers */
  background: -moz-linear-gradient(45deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 70%, #f5f5f5 70%, #f5f5f5 70%, #f5f5f5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 70%, #f5f5f5 70%, #f5f5f5 70%, #f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 70%, #f5f5f5 70%, #f5f5f5 70%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14aa6b", endColorstr="#f5f5f5",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  padding: 1.5em 2em;
}
.news-title .title {
  color: #fff;
  flex: 1;
}
@media (max-width: 1024px) {
  .news-title {
    justify-content: space-between;
    padding: 0.75em 1em;
    background: linear-gradient(45deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 60%, #f5f5f5 60%, #f5f5f5 60%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .news-title .title {
    flex: 0 0 57%;
    max-width: 57%;
  }
  .news-title .card-time {
    flex-direction: column;
    line-height: 1.25;
  }
}
.news-article {
  padding: 2em;
  background-color: #f5f5f5;
}
@media (max-width: 1024px) {
  .news-article {
    padding: 1em;
  }
}

@media (min-width: 1025px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1025px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}

.dowload-wrap {
  width: 100%;
  margin-bottom: 1rem;
}
.dowload-wrap p {
  margin-bottom: 0;
}
.dowload-wrap .btn {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0.75rem;
  padding-right: 2rem;
  color: #8c8b8b;
  border-color: #8c8b8b;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: auto;
  background-image: url("../images/icon-download-g.png");
  font-size: 16px;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dowload-wrap .btn, .dowload-wrap .btn:hover {
  background-color: #fff;
}
.dowload-wrap .btn:hover {
  color: #000;
  border-color: #015b15;
  background-image: url("../images/icon-download-b.png");
}
.dowload-item, .dowload-list {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  text-align: center;
}
.dowload-item {
  font-size: 18px;
  font-size: 1.125rem;
  color: #5f5f5f;
  border-bottom: 2px solid #a7a7a7;
  justify-content: space-between;
  padding-top: 10px;
}
@media (max-width: 640px) {
  .dowload-item {
    display: none;
  }
}
.dowload-list {
  font-size: 20px;
  font-size: 1.25rem;
  color: #646464;
  line-height: 1.5;
}
.dowload-list li {
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
}
.dowload-list li .dowload-fileName {
  text-align: left !important;
  padding-left: 3rem;
}
@media (max-width: 640px) {
  .dowload-list {
    padding-left: 5px;
    padding-right: 5px;
  }
  .dowload-list li {
    display: block;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .dowload-list li .dowload-fileName {
    padding-left: 0;
  }
  .dowload-list li > div {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .dowload-list li > div::before {
    content: attr(data-title);
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.3;
    margin: 2.5px;
    padding: 10px;
    text-align: center;
    color: white;
    background: #028d21;
    flex: 0 0 100px;
    margin-right: 10px;
  }
}
.dowload-num {
  flex: 0 0 65px;
}
.dowload-act {
  flex: 0 0 300px;
}
.dowload-fileName {
  flex: 1;
}

.pro-heading-des {
  border-bottom: 1px solid #676767;
  margin-top: 20px;
  margin-bottom: 45px;
}
.pro-heading-img {
  margin-bottom: 20px;
}
.pro-heading-txt {
  color: #464545;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 10px 15px;
}

@media (min-width: 1025px) {
  .Prod-conInfo {
    align-items: flex-start;
    margin-top: 3.5em;
  }
}
.Prod-conInfo-img {
  overflow: hidden;
}
@media (min-width: 1025px) {
  .Prod-conInfo-img {
    flex-basis: 750px;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .Prod-conInfo-img {
    flex-basis: 600px;
  }
}
@media (max-width: 1024px) {
  .Prod-conInfo-img {
    padding-top: 0.5em;
  }
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  background-size: cover;
  background-position: center;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  color: #000;
  top: auto;
  bottom: -6.5789473684vw;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (max-width: 810px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -6.5em;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -4em;
  }
}
@media (max-width: 1024px) {
  .Prod-conInfo-img .swiper-button-next {
    right: 0;
  }
  .Prod-conInfo-img .swiper-button-prev {
    left: 0;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  height: 80%;
  width: 100%;
  margin-bottom: 0.25em;
}
@media (min-width: 1025px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    margin-bottom: 15px;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (max-width: 1024px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide img {
    height: 150px;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide img {
    height: 70px;
  }
}
.Prod-conInfo-info {
  padding-top: 0.5em;
}
@media (min-width: 1025px) {
  .Prod-conInfo-info {
    flex: 1;
    padding-left: 2.75em;
  }
}
.Prod-heading {
  line-height: 1.5;
  padding-bottom: 0.75em;
  border-bottom: 1px solid #898989;
}
.Prod-heading, .Prod-headingTxt {
  padding-left: 0.75em;
}
.Prod-headingTxt {
  padding-top: 1em;
  padding-bottom: 1.5em;
}
.Prod-unreset {
  border-top: 1px solid #898989;
  border-bottom: 1px solid #898989;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Prod-unreset dl dt {
  width: 185px;
  font-weight: normal;
  padding-left: 1.2em;
  padding-right: 1.2em;
}
.Prod-unreset dl dd {
  flex: 1;
}
@media (max-width: 810px) {
  .Prod-unreset {
    padding-top: 1em;
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
  }
}
@media (max-width: 810px) and (max-width: 810px) {
  .Prod-unreset dl {
    flex-direction: column;
    margin-bottom: 0.25em;
  }
  .Prod-unreset dl dt,
.Prod-unreset dl dd {
    padding-left: 0.2em;
  }
}
.Prod-conTxt {
  border-bottom: 1px solid #323232;
  margin-bottom: 1.5em;
}
@media (min-width: 1025px) {
  .Prod-conTxt {
    padding: 1.5em 0;
    margin-left: 4em;
    margin-right: 3em;
    margin-bottom: 4em;
  }
}
.Prod-photo-small {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media (min-width: 1025px) {
  .Prod-photo-small {
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 15px;
  }
}