@use "sass:math";

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap');

$colorMainBg:#e6eaea;
$bgSecondary:#eeeeee;
$colorBlack: #000;
$colorBack: #fff;
$colorBase: #222222;
$colorMain: #015b15; // 主色
$colorItem: #595757; // 項目顏色

$colorSubs: #13ac6d; // 輔色
$colorCont: #7eba2e; // 輔色2
$colorSubsCont: #374a6f; // 輔色2
$colorText: #353535; // 內容文字顏色
$colorSubText: #5f5f5f; // 內容文字顏色2
$colorList:#0c3766; // 列表文字顏色
$colorSecondary:#0160f8;
$colorErro: #BC2837;
$colorSucc: #339C3F;
$colorWarr: #E99A2C;
$colorInfo: #32abea;
$colorFB: #3b5998;
$colorLINE: #00BA01;
$colorTWIT: #1DA1F2;
$colorFooter: #202020;
$fzBase:16px;
$fzSubtit:18px;
$fzContent:20px;
$fontBs: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$fontNotosanc-tc : 'Noto Sans TC';
$Roboto: 'Roboto';
$OpenSans-300: 'Open Sans';
$Alfa: 'Alfa Slab One';
$tensentype : "tensentype-ruihei", sans-serif;
$Kanit:'Kanit', sans-serif;

// End

// 基本字形
%baseFont {
    color: $colorBase;
    font-size: $fzSubtit;
    // font-family: $fontNotosanc-tc, 'Microsoft JhengHei', '微軟正黑體', 'Arial', sans-serif;
    -webkit-text-stroke: 0.1px;
    line-height: 1.65;
    font-weight:400;
}

$cubic-FiSo: cubic-bezier(.37,
.99,
.92,
.96); //快進場緩停
$cubic-SiRo: cubic-bezier(.31,
1.26,
.19,
1.11); //快速回彈
$cubic-SiMo: cubic-bezier(.3,
1,
.94,
1.1); //緩進微彈
$sh_sans: 'source-han-sans-traditional',
'微軟正黑體',
sans-serif; // 無襯線(思源黑體)
$sh_serif:'source-han-serif-tc',
serif; // 襯線(思源宋體)
//google Font
$nt_sans: 'Arial',
'Noto Sans TC',
'微軟正黑體',
sans-serif; //思源黑體
$Roboto: 'Roboto'; //Roboto
$RozhaOne: 'Rozha One';
$Mont: 'Montserrat',
sans-serif; //Montserrat
// popup
$popup-width: 800px;
$popup-height: 900px;
//最大寬度
@mixin maxWidth($size) {
    width: 100%;
    max-width: $size;
    margin: auto;
}

// 區塊繼承設定
%pm0 {
    margin: 0px;
    padding: 0px;
}

%setBack {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

%setFlex {
    display: flex;
    flex-wrap: wrap;
}

%setFlex-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

%setFlex-alignCenter {
    display: flex;
    align-items: center
}

%border-bDashed {
    border-bottom: 1px dashed #888;
}

%formGroup-style {
    border: #dcdcdc 1px solid;
    margin-top: 1rem;
}

%imgCenter{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    margin: auto;
}

// 文字大小
@function calculateRem($size) {
    @return math.div($size, 16px) * 1rem;
}

@function calculateEm($size) {
    @return math.div($size, 16px) * 1em;
}

@mixin fontSize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin fontSizeEm($size) {
    font-size: $size;
    font-size: calculatEm($size);
}

@function get-vw($target) {
    $vw-context: (1900 * 0.01) * 1px;
    @return math.div($target, $vw-context) * 1vw;
}

@function get-vw-per($target) {
    $vw-context: (1900 * 0.01) * 1%;
    @return math.div($target, $vw-context) * 1vw;
}

@function get-vw-mobile($target) {
    $vw-context: (640 * 0.01) * 1px;
    @return math.div($target, $vw-context) * 1vw;
}

@function vmaxCalc($vmax) {
    @return calc(#{$vmax} * (#{1vw} + #{1vh} - #{1vmin}));
}
// usage: width: vmaxCalc(75);
// result: width: calc(75 * (1vw + 1vh - 1vmin));
// which is the same as: width: 75vmax;

//Browser compatibility
@mixin setBC($k, $v) {
    -webkit-#{$k}: $v;
    -moz-#{$k}: $v;
    -ms-#{$k}: $v;
    -o-#{$k}: $v;
    #{$k}: $v;
}

@mixin setBack($rep:no-repeat, $pos:50% 50%, $siz:cover) {
    background: {
        repeat: $rep;
        position: $pos;
        size: $siz;
    }
}

@mixin setBackSet($position,$size) {
    background-repeat: no-repeat;
    background-position: $position;
    background-size: $size;
}

@mixin setSize($w:0, $h:0) {
    width: $w;
    height: $h;
}

@mixin setProportion($proportion: 100%, $bs: contain) {
    // 等比例區塊
    background-size: $bs;
    &::before {
        content: '';
        display: block;
        padding-top: $proportion;
        @content;
    }
}

// 分配字距justify
@mixin setJustify() {
    text-align: justify;
    text-justify: inter-ideograph;
    &::after {
        content: "";
        display: inline-block;
        width: 100%;
    }
}

// 自訂flex
@mixin setFlexStyle($ai,$jc) {
    display: flex;
    flex-flow: row wrap;
    align-items:$ai;
    justify-content:$jc;
}

//Delay Animate
@mixin setDelay($ty,
$e,
$tv,
$btv: 0,
$s: 1) {
    @for $i from $s through $e {
        &:nth-child(#{$i}) {
            #{$ty}: #{$btv + $i * $tv}s;
            @content;
        }
    }
}

@mixin setStr($el,
$ty,
$baseStr,
$e,
$attcStr: '',
$s: 1) {
    @for $i from $s through $e {
        &:nth-child(#{$i})#{$el} {
            #{$ty}:#{$baseStr}#{$i}#{$attcStr};
            @content;
        }
    }
}

// 設定進場：未進場樣式, 進場樣式, 動畫曲線(預設)
@mixin setIntoView($basc, $show, $cubic: .85s $cubic-SiRo) {
    @each $k,
    $v in $basc {
        #{$k}: $v;
    }
    transition: $cubic;
    &.animation {
        @each $k,
        $v in $show {
            #{$k}: $v;
        }
        @content;
    }
}

// 隱藏文字
@mixin hide-text {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin borderStyle($style) {
    border-style:$style;
}

@mixin borderRadius($num) {
   border-radius: $num+px;
}

@mixin radius($num) {
    border-radius:$num;
}

// fonSize fun
$root-font-size: 100% !default;
$sizes: 87.5%, 100%, 112.5%, 150%, 175.5%, 250%, 275%, 362.5%, 450% !default;

@function size($i) {
  @return nth($sizes, $i);
}

@mixin font-sizes {
  @for $i from 1 through length($sizes) {
    .font-size-#{$i} {
      font-size: size($i) !important;
    }
  }
}

// 行高 FUN
$lineHeight: 1, 1.25, 1.5, 1.75, 2 !default;

@function lineSize($i) {
  @return nth($lineHeight, $i);
}

@mixin line-height {
  @for $i from 1 through length($lineHeight) {
    .line-height-#{$i} {
      line-height: lineSize($i) !important;
    }
  }
}

// Output the mixin's classes
@include font-sizes;
@include line-height;

.noJS>div,
.noJS>ul {
    display: none;
}

.noJS>noscript {
    display: block;
    width: 100%;
    line-height: 10em;
}

// 圖片
@mixin imgObject($x,$y){
    object-fit: $x;
    object-position: $y;           
}

// End
