// 產品項目說明
.pro-heading{
    &-des{
        border-bottom: 1px solid #676767;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    &-img{
        margin-bottom: 20px;
    }

    &-txt{
        color:#464545;
        @include fontSize(18px);
        padding: 10px 10px 15px;
    }
}

// 產品詳細頁
.Prod{
    &-conInfo{
        @include setMinRwd{
            align-items: flex-start;
            margin-top: 3.5em;
        }

        &-img{
            overflow: hidden;
            @include setMinRwd{
                flex-basis: 750px;
            }

            @include setWidthRwd(1101px,1600px){
                flex-basis: 600px;
            }

            @include setMaxRwd{
                padding-top: 0.5em;
            }

            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    background-size: cover;
                    background-position: center;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &-button-next,
                &-button-prev {
                    color: #000;
                    top: auto;
                    bottom: get-vw(-125px);
                    &::after{
                        @include fontSize(20px);
                        @extend .fw-900;
                    }
                    
                    @include pad{
                        bottom: -6.5em;
                    }
                    
                    @include mobile{
                        bottom: -4em;
                    }
                }

                @include setMaxRwd{
                    &-button-next{
                        right: 0;
                    }

                    &-button-prev{
                        left: 0;
                    }
                }
            }
            
            // 大圖
            .Prod_mySwiper2 {
                height: 80%;
                width: 100%;
                margin-bottom: 0.25em;
                @include setMinRwd{
                    margin-bottom: 15px;
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                .swiper-slide {
                    width: 25%;
                    height: 100%;
                    opacity: 0.4;
                    &-thumb-active {
                        opacity: 1;
                    }
                
                    @include setMaxRwd{
                        img{
                            height: 150px;
                        }
                    }
                
                    @include mobile{
                        img{
                            height: 70px;
                        }
                    }
                }
            }
        }

        &-info{
            padding-top: 0.5em;
            @include setMinRwd{
                flex: 1;
                padding-left: 2.75em;
            }
        }
    }

    &-heading{
        line-height: 1.5;
        padding-bottom: 0.75em;
        border-bottom: 1px solid #898989;

        &,
        &Txt{
            padding-left: 0.75em;
        }

        &Txt{
            padding-top: 1em;
            padding-bottom: 1.5em;
        }
    }

    &-unreset{
        border-top: 1px solid #898989;
        border-bottom: 1px solid #898989;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        dl{
            @extend %setFlex;
            dt{
                width: 185px;
                font-weight: normal;
                padding-left: 1.2em;
                padding-right: 1.2em;
            }
            dd{
                flex: 1;
            }
        }

        @include pad{
            padding-top: 1em;
            padding-bottom: 0.5em;
            margin-bottom: 1.5em;
            @include pad{
                dl{
                    flex-direction: column;
                    margin-bottom: 0.25em;
                    dt,
                    dd{
                        padding-left: 0.2em;
                    }
                }
            }
        }
    }

    &-conTxt{
        border-bottom: 1px solid #323232;
        margin-bottom: 1.5em;
        @include setMinRwd{
            padding: 1.5em 0;
            margin-left: 4em;
            margin-right: 3em;
            margin-bottom: 4em;
        }
    }

    &-photo-small{
        padding-left: 1.5em;
        padding-right: 1.5em;
        @include setMinRwd{
            padding-left: 3em;
            padding-right: 3em;
            margin-top: 15px;
        }
    }
}