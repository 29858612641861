.inMain{
    &-gradient{
        background: #14aa6b; /* Old browsers */
        @include setMinRwd{
            background: -moz-linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(60deg, #14aa6b 1%, #14aa6b 27%, #14aa6b 65%, #f5f5f5 65%, #f5f5f5 65%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14aa6b', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }

    &-wrap{
        @extend %setFlex-center;
        padding: 1.25em;
        margin-top: 20px;
        margin-bottom: 20px;
        @include setMinRwd{
            padding: 50px;
        }
    }

    &-list{
        flex:1;
        @include setMinRwd{
            padding-right: 20px;
        }
    }

    &-title{
        @include fontSize(26px);
        @include setMinRwd{
            color: #fff;
            margin-bottom: 1em;
            @include fontSize(48px);
        }
    }

    &-txt{
        @include setMinRwd{
            @include fontSize(24px);
        }
    }

    &-img{
        @include setMaxRwd{
            margin-bottom: 10px;
        }

        @include pad{
            order: -1;
        }
    }

    &-form{
        flex: 0 0 740px;
        max-width: 740px;
        @include setMaxRwd{
            flex: none;
            width: 100%;
        }
    }

    @include setWidthRwd(1020px,1100px){
        &-img{
            flex: 0 0 20em;
            margin: 0 10px;
        }
    }
}