$card-left: 0.25em;
$card-right: 0.25em;

$mobile-card-left: 0.5em;
$mobile-card-right: 0.5em;

$card-btn :#aaaaaa;
$card-btn-active :#252525;

.card{
    flex-grow: 1;
    margin-left: $card-left;
    margin-right: $card-right;
    border: none;
    text-align: left;
    @include radius(0);

    // Grid
    &__2{
        @include setMinRwd{
            flex: 0 0 calc(100% / 2 - ($card-left + $card-right));
            max-width: calc(100% / 2 - ($card-left + $card-right));
        }
    }

    &__3{
        @include setMinRwd{
            flex: 0 0 calc(100% / 3 - ($card-left + $card-right));
            max-width: calc(100% / 3 - ($card-left + $card-right));
        }
    }

    &__4{
        @include setMinRwd{
            flex: 0 0 calc(100% / 4 - ($card-left + $card-right));
            max-width: calc(100% / 4 - ($card-left + $card-right));
        }
    }

    @include setMaxRwd{
        margin-left: $mobile-card-left;
        margin-right: $mobile-card-right;
        flex: 0 0 calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
        max-width: calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
    }

    &__full{
        flex: 0 0 calc(100% - ($card-left + $card-right)) !important;
        max-width: calc(100% - ($card-left + $card-right)) !important;
    }

    &__horizontal{
        @include setMinRwd{
            flex-direction: row;
        }
    }
    // End

    &-img-top{
        @include radius(0px);

        img{
            display: block;
            width: 100%;
        }

        & + .card-caption{
            margin-top: 10px;
        }
    }

    &-time{
        @extend %setFlex-center;
        font-weight: bold;
        .date{
            color:$colorMain;
            @include fontSize(24px);
            padding-right: 10px;
            & + .year{
                &::before{
                    content: ' / ';
                    display: inline-block;
                    padding-right: 5px;
                }
            }
        }
        .year{
            @include fontSize(16px);
            color: #8b8b8b;
        }
    }

    &-title{
        @include fontSize(23px);
        font-weight: 900;
    }

    &-caption{
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    }

    &-body{
        padding-bottom: 1.25em;
    }

    &-text{
        @include fontSize(16px);
        color: $colorItem;
    }

    &-btn{
        width: 100%;
        @include fontSize(16px);
        font-family: $OpenSans-300;
        font-weight: 900;
        color: $card-btn;
        padding-left: 0 !important;
        margin-top: 2em;
        &::after{
            max-width: 200px;
            background-color: $card-btn;
            margin-left: 10px;
        }
    }

    // 框
    &-wrap{
        @extend %setFlex;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;

        &-index{
            &News{
                @include setMinRwd{
                    padding-left: 50px;
                    padding-right: 50px;
                    
                    &-img{
                        max-width: 515px;
                    }
                    
                    &-list{
                        max-width: calc(100% - 515px);
                        padding-left: 60px;
                        .card-caption{
                            padding-top: 0;
                        }
                    }
                }

                @include setWidthRwd(1020px,1100px){
                    &-img{
                        max-width: 350px;
                    }
                    
                    &-list{
                        max-width: calc(100% - 350px);
                        padding-left: 10px;
                    }
                }

                @include setWidthRwd(1100px,1500px){
                    &-img{
                        max-width: 400px;
                    }
                    
                    &-list{
                        max-width: calc(100% - 400px);
                        padding-left: 20px;
                    }
                }

                @include setMaxRwd{
                    .card-caption {
                        padding-left: 0;
                    }

                    &-img{
                        .card{
                            flex: 0 0 calc(100% - 1em);
                            max-width: calc(100% - 1em);
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &Application{
                .card{
                    background-color: transparent;

                    &-title{
                        @include fontSize(36px);
                        color: $colorSubs;
                        @include setMaxRwd{
                            @include fontSize(20px);
                            margin-bottom: 0.25em;
                        }
                    }

                    &-text{
                        @include setMaxRwd{
                            @include fontSize(14px);
                            line-height: 1.1;
                        }
                    }

                    &-caption {
                        line-height: 0.5;
                        position: absolute;
                        bottom: -2.65em;
                        left: 2em;
                        width: calc(100% - 4em);
                    }

                    @include setMinRwd{
                        padding-left: 20px;
                        padding-right: 20px;

                        &-img-top{
                            &::before{
                                content:'';
                                @include setSize(100%,100%);
                                position: absolute;
                                left: 0;
                                top: 0;
                                opacity: 0;
                                transition: 0s;
                            }
                        }

                        // 
                        &:hover{
                            .card-img-top{
                                &::before{
                                    background: rgba(19,44,24,0.7);
                                    opacity: 1;
                                    transition: 0.25s;
                                }
                            }

                            .card-caption{
                                width: calc(100% - 40px);
                                top: 0;
                                left: 20px;
                                bottom: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                margin-top: 0;
                                overflow: hidden;
                            }

                            .card-title,
                            .card-text{
                                position: relative;
                                z-index: 1;
                                color:#fff
                            }
                        }
                    }

                    @include setMaxRwd{
                        margin-bottom: 1em !important;

                        &-caption {
                            width: calc(100% - 1em);
                            position: static;
                            margin-top: 0;
                        }
                    }
                }

                @include setMinRwd{
                    margin-left: -20px;
                    margin-right: -20px;
                }

                &Top{
                    align-items: flex-start;
                    .card{
                        &:nth-child(2){
                            @include setMinRwd{
                                margin-top: 40px;
                                &::after{
                                    content: '';
                                    display: block;
                                    @include setSize(300px,60px);
                                    max-width: 100%;
                                    max-height: 100%;
                                    @include setBackSet(top,contain);
                                    background-image: url('../images/alpha-trapezoidal.png');
                                    position: absolute;
                                    bottom: 0;
                                    left: 150px;
                                    z-index: -1;
                                    opacity: 0;
                                    transition: 0.25s;
                                }
    
                                &:hover{
                                    &::after{
                                        left: 6%;
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        &:nth-child(3){
                            margin-top: 10px;
                            .card-img-top{
                                &::after{
                                    content: '';
                                    display: block;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 100px 100px 0 0;
                                    border-color: #13ac6c transparent transparent transparent;
                                    position: absolute;
                                    top: 0;
                                    left: 0;

                                    @include setMaxRwd{
                                        border-width: 50px 50px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }

                &Bottom{
                    .card{
                        @include setMinRwd{
                            &:first-child{
                                .card-img-top{
                                    &::after{
                                        content: '';
                                        display: block;
                                        @include setSize(96%,92%);
                                        border: #fff 1px solid;
                                        border-bottom-width: 0;
                                        position: absolute;
                                        top: 4%;
                                        left: 2%;
                                        z-index: 1;
                                    }
                                }

                                .card-caption{
                                    padding-left: 0;
                                    padding-right: 0;
                                }

                                .card-title{
                                    display: flex;
                                    align-items: center;
                                    &::before,
                                    &::after {
                                        content: " ";
                                        display: block;
                                        border-bottom: 1px solid #fff;
                                        flex: 1;
                                        margin-top: -0.375em;
                                    }

                                    &::before{
                                        flex: 0 0 20px;
                                        margin-right: 10px;
                                        margin-left: -2px;
                                    }

                                    &::after{
                                        margin-right: -2px;
                                        margin-left: 10px;
                                    }
                                }

                                .card-text{
                                    padding-left: 32px;
                                }
                                
                                &:hover{
                                    .card-img-top{
                                        &::after{
                                            border-bottom-width: 1px;
                                        }
                                    }

                                    .card-title{
                                        &::before,
                                        &::after{
                                            display: none;
                                        }
                                    }

                                    .card-text{
                                        padding-left: 0;
                                    }
                                }
                            }

                            &:last-child{
                                &::after{
                                    content: '';
                                    @include setSize(223px,181px);
                                    max-width: 100%;
                                    max-height: 100%;
                                    @include setBackSet(bottom,contain);
                                    background-image: url('../images/cardImg-application05-item.png');
                                    position: absolute;
                                    bottom: -20%;
                                    right: -5%;

                                    @include setWidthRwd(1020px,1100px){
                                        @include setSize(150px,130px);
                                    }

                                    @include setMaxRwd{
                                        @include setSize(100px,75px);
                                    }
                                }
                            }
                        }
                    }

                    @include setMinRwd{
                        margin-top: 6em;
                    }
                }
            }

            @include setMinRwd{
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }

    &:hover{
        .card-btn{
            color: $card-btn-active;
            &::after{
                background-color:  $card-btn-active;
            }
        }
    }

    @include setMaxRwd{
        margin-bottom: 1em;

        &-time{
            .date,
            .year{
                margin-bottom: 0;
            } 
        }
        
        &-title{
            @include fontSize(20px);
            margin-top: 0.25em;
        }

        &-body{
            padding: 0.75em 0; 
        }

        &-caption {
            padding-top: 0.125em;
            padding-bottom: 0.25em;
        }
    }
}

// 內頁
.news{
    .card{
        &-caption{
            padding-left: 0;
            padding-right: 0;
        }

        @include setMinRwd{
            margin-left: 1.5em;
            margin-right: 1.5em;
            flex: 0 0 calc(100% / 3 - (1.5em + 1.5em));
            max-width: calc(100% / 3 - (1.5em + 1.5em));
            &-img-top {
                margin-bottom: 1.5em;
            }
            
            &-btn {
                margin-top: 1.25em;
            }

            // 水平狀態
            &__horizontal{
                background: #14aa6b; /* Old browsers */
                background: -moz-linear-gradient(65deg,  #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(65deg,  #14aa6b 1%,#14aa6b 30%,#14aa6b 27%,#f5f5f5 27%,#f5f5f5 27%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(65deg,  #14aa6b 1%,#14aa6b 30%,#14aa6b 27%,#f5f5f5 27%,#f5f5f5 27%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14aa6b', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

                padding: 25px;
                margin-bottom: 50px;
                .card-img-top {
                    flex: none;
                    width: 510px;
                    margin-right: 50px;
                    margin-bottom: 0;
                }
                
                .card-caption{
                    padding-left: 0;
                }

                .card-body {
                    display: flex;
                    flex-direction: column;
                    .card-btn{
                        margin-top: auto;
                    }
                }
            }
        }
    }
}