.dowload{
    &-wrap{
        width: 100%;
        margin-bottom: 1rem;
        p{
            margin-bottom: 0;
        }
        
        .btn{
            display: block;
            width: 100%;
            text-align: left;
            padding:0.5rem 0.75rem;
            padding-right: 2rem;
            color:#8c8b8b;
            border-color: #8c8b8b;
            @include setBackSet(calc(100% - 10px),auto);
            background-image: url('../images/icon-download-g.png');
            @include fontSize(16px);
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &,
            &:hover{
                background-color: #fff;
            }
            &:hover{
                color:#000;
                border-color: $colorMain;
                background-image: url('../images/icon-download-b.png');
            }
        }
    }

    &-item,
    &-list{
        padding-left:15px;
        padding-right: 15px;
        padding-bottom: 20px;
        text-align: center;
    }

    &-item{
        @include fontSize(18px);
        color:#5f5f5f;
        border-bottom: 2px solid #a7a7a7;
        @extend %setFlex;
        justify-content: space-between;
        padding-top: 10px;
        @include mobile{
            display: none;
        }
    }

    &-list{
        @include fontSize(20px);
        color:#646464;
        line-height: 1.5;
        li{  
            @extend %setFlex;
            justify-content: space-between;
            border-bottom: 2px solid #e1e1e1;
            padding-top: 1.5rem;
            padding-bottom: 0.75rem;
            .dowload-fileName{    
                text-align: left !important;
                padding-left: 3rem;
            }
        }
        
        @include mobile{
            padding-left: 5px;
            padding-right: 5px;
            li{
                display: block;
                text-align: left;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                .dowload-fileName{    
                    padding-left: 0;
                }
                &>div{
                    margin-bottom: 0.5rem;
                    display: flex;
                    align-items: center;
                    &::before{
                        content: attr(data-title);
                        display: inline-block;
                        @include fontSize(14px);
                        font-weight: 100;
                        line-height: 1.3;
                        margin: 2.5px;
                        padding: 10px;
                        text-align: center;
                        color: white;
                        background: lighten($colorMain, $amount: 10);
                        flex: 0 0 100px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &-num{
        flex: 0 0 65px;
    }
    &-act{
        flex: 0 0 300px;
    }
    &-fileName{
        flex: 1;
    }
}