.news{
    &-box{
        @include setMinRwd{
            padding-left: 1.25em;
            padding-right: 1.25em;
        }
    }

    &-title{
        @extend %setFlex-center;
        @include setSize(100%,height);
        background: #14aa6b; /* Old browsers */
        background: -moz-linear-gradient(45deg,  #14aa6b 1%, #14aa6b 27%, #14aa6b 70%, #f5f5f5 70%, #f5f5f5 70%, #f5f5f5 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg,  #14aa6b 1%,#14aa6b 27%,#14aa6b 70%,#f5f5f5 70%,#f5f5f5 70%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg,  #14aa6b 1%,#14aa6b 27%,#14aa6b 70%,#f5f5f5 70%,#f5f5f5 70%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14aa6b', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

        padding: 1.5em 2em;

        .title{
            color: #fff;
            flex: 1;
        }

        @include setMaxRwd{
            justify-content: space-between;
            padding: 0.75em 1em;
            background: linear-gradient(45deg,  #14aa6b 1%,#14aa6b 27%,#14aa6b 60%,#f5f5f5 60%,#f5f5f5 60%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            .title{
                flex: 0 0 57%;
                max-width: 57%;
            }

            .card-time{
                flex-direction: column;
                line-height: 1.25;
            }
        }
        
    }

    &-article{
        padding: 2em;
        background-color: #f5f5f5;
        @include setMaxRwd{
            padding:1em;
        }
    }
}